import React from 'react'
import {
  CFView,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import {
  downloadAppButton,
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView mb="10%">
            <OrderPickupButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          w="100%"
          maxHeight="690px"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={90}
          column
          justifyBetween
        >
          <Header />
          <CFView mb="3%" ml="3%">
            <OrderPickupButton />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
