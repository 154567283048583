import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFImage,
  CFView,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  about,
  aboutParallax,
  mobileAbout,
  mobileAboutParallax,
  rated,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileAbout} w="100%" alt="About" />
          <CFLink
            href="https://www.thebestvancouver.com/best-japanese-restaurant-vancouver/"
            target="_blank"
          >
            <CFView column center mt="-15px" mb="20px">
              <CFImage w="80%" src={rated} alt="About" />
            </CFView>
          </CFLink>
        </CFView>
        <Parallax
          bgImage={mobileAboutParallax}
          bgImageAlt="Entree Dish"
          strength={200}
        >
          <div style={{ height: '300px' }} />
        </Parallax>
      </MobileScreen>
      <DefaultScreen>
        <CFView column justifyStart w="100%">
          <CFImage src={about} w="100%" maxWidth="1400px" alt="About" />
          <CFLink
            href="https://www.thebestvancouver.com/best-japanese-restaurant-vancouver/"
            target="_blank"
          >
            <CFView w="50%" mt="-10%" column center ml="-20px">
              <CFImage w="55%" src={rated} alt="About" />
            </CFView>
          </CFLink>
        </CFView>
        <CFView
          row
          justifyStart
          alignCenter
          pv="10%"
          pr="40px"
          h="600px"
          maxWidth="1400px"
          image={`url(${aboutParallax}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
