import React from 'react'
import { Parallax } from 'react-parallax'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  mobilePromotion,
  mobilePromoParallax,
  promotions,
  promotionsParallax,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobilePromotion} w="100%" alt="Promotions" mt="15px" />
        </CFView>
        <Parallax
          bgImage={mobilePromoParallax}
          bgImageAlt="Entree Dish"
          strength={200}
        >
          <div style={{ height: '300px' }} />
        </Parallax>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1400px" pt="40px">
          <CFImage
            src={promotions}
            w="100%"
            maxWidth="1300px"
            mb="30px"
            alt="Promotions"
          />
          <CFView
            row
            justifyStart
            alignCenter
            pv="10%"
            pr="40px"
            h="590px"
            w="100%"
            maxWidth="1400px"
            image={`url(${promotionsParallax}) center / cover no-repeat`}
            style={{ backgroundAttachment: 'fixed' }}
          ></CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
